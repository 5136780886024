import {
  useController,
  useFormContext,
} from 'react-hook-form'
import { ComGate } from 'shopify-payments-types'

import {
  Card,
  Select,
  Link,
  TextContainer,
  SelectOption,
} from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export function SettingsOrderNameType({ orderNameTypes }: { orderNameTypes: SelectOption[] }) {
  const account = useAccount<ComGate.TSegmentAccount>()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'orderNameType',
    defaultValue: account.segmentConfig.orderNameType || 'number',
  })

  const scopes = account.shopifyAccessScopes?.split(",") ?? [];
  if(!scopes.includes('read_orders')) {
    return (
      <Card.Section>
        <TextContainer>
          <p>{getTranslation(
            'Synchronizace ID objednávky',
            'Order ID synchronization',
          )}</p>
          <p style={{ color: '#aa8414' }}>{getTranslation(
            'Čísla objednávek nejsou synchronizována.',
            'Order numbers are not synchronized.',
          )}</p>
          <p style={{ color: '#6d7175' }}>{getTranslation(
            'Není možné zvolit formát "Synchronizace ID objednávky", protože platební doplněk Comgate nemá přístup k informacím o názvech objednávek.',
            'It is not possible to select the \'Order ID synchronization\' format because the Comgate payment plugin does not have access to the order name information.',
          )}</p>
        </TextContainer>
        <p style={{ marginTop: '16px' }}>
          <Link
            external={true}
            url={getTranslation(
              'https://help.comgate.cz/docs/shopify#jak-povolit-platebn%C3%ADmu-dopl%C5%88ku-comgate-p%C5%99%C3%ADstup-k-informac%C3%ADm-o-n%C3%A1zvech-objedn%C3%A1vek-v-syst%C3%A9mu-shopify',
              'https://help.comgate.cz/docs/en/shopify#how-to-allow-comgate-to-access-order-name-information-in-shopify',
            )} >{getTranslation(
            'Pro povolení synchronizace postupujte podle návodu v helpu.',
            'To enable synchronization, follow the instructions in the help guide.',
          )}</Link>
        </p>
      </Card.Section>
    );
  }

  return (
    <Card.Section>
      <Select
        helpText={getTranslation(
          'Zvolte "Číslo objednávky", pokud chcete synchronizovat pouze pořadová čísla objednávek (1005). Jinak zvolte "Název objednávky", kdy bude synchronizováno ID objednávky i s předponou a příponou (#1005s).',
          "Choose \"Order number\" if you only want to synchronize order serial numbers (1005). Otherwise, choose \"Order name\", when the order ID will be synchronized with the prefix and suffix (#1005s).",
        )}
        label={getTranslation(
          'Synchronizace ID objednávky',
          'Order ID synchronization',
        )}
        value={field.value}
        options={orderNameTypes}
        onChange={(selected) => {
          setValue(field.name, selected, {
            shouldDirty: true,
            shouldTouch: true,
          })
        }}
      />

      <Link
        external={true}
        url={getTranslation(
        'https://help.comgate.cz/docs/shopify#form%C3%A1t-%C4%8D%C3%ADsla-objedn%C3%A1vky-ze-shopify',
        'https://help.comgate.cz/docs/en/shopify#the-format-of-a-shopify-order',
      )} >{getTranslation(
        'Více informací naleznete v helpu.',
        'More information can be found in the help.',
      )}</Link>
    </Card.Section>
  )
}
