import '@shopify/polaris/build/esm/styles.css'

import React from 'react'

import {
  Frame,
  Page,
  Stack,
} from '@shopify/polaris'

import { useBackToShopAction } from '../hooks/useBackToShopAction'
import { useSegmentLabel } from '../hooks/useSegmentLabel'
import { getTranslation } from '../util/getTranslation'
import { ActivationCard } from './ActivationCard'
import { ActivationStatusBadge } from './ActivationStatusBadge'
import { useAuth } from './AuthContext'
import { ComGateAccountSettings } from './ComGateAccountSettings'
import { ComGateActivationFlow } from './ComGateActivationFlow'
import { I18nProvider } from './I18nProvider'

function App() {
  const title =
    getTranslation(
      'Konfigurace platební brány ',
      'Configuration of payment gateway ',
    ) + useSegmentLabel()

  React.useEffect(() => {
    document.title = title
  }, [title])

  const auth = useAuth()

  return (
    <I18nProvider>
      <Frame>
        <Page
          narrowWidth
          title={title}
          titleMetadata={<ActivationStatusBadge />}
          subtitle={
            getTranslation('Pro obchod ', 'For the shop ') +
            auth.account.shopDomain
          }
          secondaryActions={[useBackToShopAction()]}
        >
          <Stack vertical>
            <ActivationCard CredentialsForm={ComGateActivationFlow} />
            <ComGateAccountSettings />
          </Stack>
        </Page>
      </Frame>
    </I18nProvider>
  )
}

export default App
