import React from 'react'

import { ComGate } from 'shopify-payments-types'

import { useAccount } from '../hooks/useAccount'
import { ComGateCredentialsForm } from './ComGateCredentialsForm'
import { ComGateRegistration } from './ComGateRegistration'

type TProps = {
  onSuccess(): void
}

export function ComGateActivationFlow({ onSuccess }: TProps) {
  const {
    activationStatus,
    segmentConfig: { registrationSentAt },
  } = useAccount<ComGate.TSegmentAccount>()

  const [status, setStatus] = React.useState<
    'register' | 'credentials' | 'failed' | 'done'
  >(() => {
    if (activationStatus === 'new') {
      return registrationSentAt ? 'credentials' : 'register'
    }
    return 'done'
  })

  React.useEffect(() => {
    if (status === 'done') {
      onSuccess()
    }
  }, [status, onSuccess])

  if (status === 'register') {
    return <ComGateRegistration onSuccess={() => setStatus('credentials')} />
  } else if (status === 'credentials') {
    return <ComGateCredentialsForm onSuccess={() => setStatus('done')} />
  }

  return null
}
