import { FormProvider } from 'react-hook-form'

import {
  Button,
  Card,
  Form,
  SelectOption,
  Stack,
  Toast,
} from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { SettingsContactCard } from './SettingsContactCard'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsOrderNameType } from './SettingsOrderNameType'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'

export function ComGateAccountSettings() {
  const config = useAuth()
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm(
    (api, formValues) => api.saveConfig(formValues),
  )

  if (config.account.activationStatus === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          {/* <ComGateSettingsBankAccount /> */}
          <SettingsContactCard />
          <SettingsLanguage languages={getLanguages()} />
          <SettingsOrderNameType orderNameTypes={getOrderNameTypes()} />
          <SettingsPaymentLabel />
          <Card.Section>
            <Stack distribution="trailing">
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: 'Automaticky' },
    { value: 'cs', label: 'Čeština' },
    { value: 'en', label: 'Angličtina' },
    { value: 'sk', label: 'Slovenština' },
    { value: 'pl', label: 'Polština' },
  ]
}

function getOrderNameTypes(): SelectOption[] {
  return [
    { value: 'number', label: getTranslation('Číslo objednávky (1005)', 'Order number (1005)') },
    { value: 'name', label: getTranslation('Název objednávky (#1005s)', 'Order name (#1005s)') },
  ]
}
