import React from 'react'

import { TAccountActivationStatus } from 'shopify-payments-types'

import {
  Banner,
  Collapsible,
  Stack,
  TextContainer,
  Toast,
} from '@shopify/polaris'

import { useSegmentLabel } from '../hooks/useSegmentLabel'
import { useShopifyActivation } from '../hooks/useShopifyActivation'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'

type TProps = {
  CredentialsForm: React.FunctionComponent<{ onSuccess: () => Promise<void> }>
}

export function ActivationCard(props: TProps) {
  const { account, refreshAccount } = useAuth()

  const activation = useShopifyActivation()
  const [status, setStatus] = React.useState<TAccountActivationStatus>(
    account.activationStatus,
  )

  const onCredentialsVerified = React.useCallback(async () => {
    const account = await refreshAccount()
    setStatus(account.activationStatus)
  }, [refreshAccount])

  const segmentLabel = useSegmentLabel()

  if (account.activationStatus === 'active' && account.status === 'ready') {
    // Final state, nothing to do here.
    return null
  }

  return (
    <>
      <Collapsible id="new" open={status === 'new'}>
        <Stack>
          <Banner status="info">
            <p>
              {getTranslation(
                `Následující průvodce vás povede při propojení ${segmentLabel} brány s Vaším obchodem.`,
                `The following guide will lead you through the ${segmentLabel} gateway connection to your store.`,
              )}{' '}
              {getTranslation(
                'Prosím, čtěte pozorně instrukce pro bezproblémový postup',
                'Please read the instructions carefully in order to avoid any trouble',
              )}
            </p>
          </Banner>
          <props.CredentialsForm onSuccess={onCredentialsVerified} />
        </Stack>
      </Collapsible>
      <Collapsible id="verified" open={status !== 'new'}>
        <Banner
          status="success"
          action={activation.action}
          title={getTranslation(
            `Údaje k propojení s ${segmentLabel} jsou oveřené.`,
            `Details for connecting with ${segmentLabel} are verified.`,
          )}
        >
          <TextContainer>
            <p>
              {getTranslation(
                'Nyní je potřeba bránu aktivovat. Tímto krokem dojde k přesměrování zpět do Shopify, kde povolíte zobrazení platební metody ve vašem obchodu.',
                'Now you need to activate the gateway. This step will take you back to Shopify, where you will allow the payment method to be displayed in your store.',
              )}
            </p>
            <p>
              {getTranslation(
                'Doporučujeme nejprve projít další nastavení níže. K těmto se ovšem můžete vrátit kdykoliv později z nastavení platebních metod v Shopify.',
                'We recommend that you go through the other settings below first. However, you can return to these at any time later from the payment method settings in Shopify.',
              )}
            </p>
          </TextContainer>
        </Banner>
      </Collapsible>
      {activation.status === 'success' && (
        <Toast
          content={getTranslation(
            'Přesouváme Vás zpět do obchodu.',
            'Redirecting you back to the shop.',
          )}
          onDismiss={activation.dismiss}
        />
      )}
      {activation.status === 'failure' && (
        <Toast
          content={getTranslation(
            'Aktivace selhala. Na nápravě pracujeme a budeme Vás kontaktovat.',
            'Activation has failed. We are working on the fix and we will contact you-',
          )}
          error
          onDismiss={activation.dismiss}
        />
      )}
    </>
  )
}
