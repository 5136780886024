import {
  Card,
  FormLayout,
} from '@shopify/polaris'

import { getTranslation } from '../util/getTranslation'
import { FormTextField } from './FormTextField'

export function SettingsContactCard() {
  return (
    <Card.Section>
      <FormLayout>
        <FormTextField
          name="registrationIco"
          label="IČ *"
          autoComplete=""
          required
        />
        <FormTextField
          name="contactEmail"
          label={`${getTranslation('Kontaktní e-mail', 'Contact e-mail')} * `}
          autoComplete="email"
          required
        />
        <FormTextField
          name="contactPhone"
          label={getTranslation('Kontaktní telefon', 'Contact phone')}
          helpText={getTranslation(
            'Pro rychlejší formu komunikace nám můžete sdělit telefonní číslo, kde vás můžeme zastihnout v případě potřeby.',
            'For a faster form of communication, you can tell us a phone number where we can reach you if needed.',
          )}
          autoComplete="tel"
          required={false}
        />
      </FormLayout>
    </Card.Section>
  )
}
