import getUserLocale from 'get-user-locale'

export function getTranslation<T>(cs: T, en: T): T {
  let locale = getUserLocale();
  if(locale) {
    locale = locale?.toLowerCase();
  }

  switch (locale) {
    case 'cs':
    case 'cz':
    case 'cs-cz':
      return cs;
    case 'en':
    case 'en-us':
    default:
      return en;
  }
}
